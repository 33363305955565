import React, {memo, useEffect, useMemo, useRef, useState} from "react";
import {connect} from "react-redux";
import s from "./index.module.less";
import {useTranslation, Trans} from 'react-i18next';
import {unit, countDownTime, getConfig, joinChat} from "@/utils/common";
import {useNavigate} from "react-router-dom";
import defaultBanner from "@/assets/images/UPArrowsBG.gif"; //默认banner
import points from "@/assets/images/points.gif"; //答题倒计时X分钟内 gif 仅home页
import CustomIcon from "../CustomIcon";

const leftMinutes = 15; //开始前X分钟
const triviaBgUrl = '/UI/Banner/UI_Banner_EFAS_01.webp';
const triviaPrefixTitle = 'EFAS x ';
const Navs = (
    {
        showAmount = false,
        navToLive = false,
        showTimer = false,
        showTimerShare = false,
        isHomeBanner = false,
        isTiviaBanner = false,
        title,
        customTimestamp = 0,
        hasCurndown = false, //是否有倒计时
        bgUrl = defaultBanner,
        children,
    }
) => {
    const {t, i18n} = useTranslation();
    if (!title) {
        title = <div className="fs22 fb">{t('Banner Title Line 1', {prefixTitle: triviaPrefixTitle})} <br/> {t('Banner Title Line 2')} </div>;
    }
    const [timeStr, setTimeStr] = useState('')
    const [amount, setAmount] = useState("-")
    const startTime = useRef(0);
    const endTime = useRef(0);
    const hostId = useRef(0);
    const [state, setState] = useState(0);
    const [titleText, setTitleText] = useState(title);
    const [showPoints, setShowPoints] = useState(false);

    const navigate = useNavigate();
    // const metaTitle = useMemo(()=>{
    //   const ind = title.indexOf('^$1');
    //   let str;
    //   if(ind !== -1){
    //     str = <>{title.replace('^$1',<CustomIcon className='ml5' width={20} height={20} imgName={'Picture/UI_Picture-Currency_LFG_01'} name={'Picture/UI_Picture-Currency_LFG_01'}/>)}</>
    //   }else{
    //     str = <>{title}</>
    //   }
    //   return str
    // },[title])
    useEffect(() => {
        if (hasCurndown && customTimestamp === 0) {
            getConfig().then(data => {
                setAmount(data?.triviaBonus)
                startTime.current = data?.triviaStartTime * 1000
                endTime.current = data?.triviaEndTime * 1000
                hostId.current = data?.hostId
                let timestamp = new Date().getTime()
                if (timestamp <= startTime.current) {
                    setState(1)
                    setTitleText(title);
                } else if (timestamp > startTime.current && timestamp <= endTime.current) {
                    setState(2)
                    setTitleText(title);
                } else {
                    // if (title === 'TRIVIA REWARD'){
                    //     setTitleText('TRIVIA FINISHED');
                    // }
                    setState(0)
                }
            })
        } else {

            startTime.current = customTimestamp * 1000;
            setState(4)
        }


        const timer = setInterval(() => {
            setTimeStr(countDownTime(startTime.current, showTimerShare ? 2 : customTimestamp === 0 ? 0 : 1));
            const nowtime = new Date();
            if (startTime.current - nowtime < leftMinutes * 60 * 1000 && nowtime <= endTime.current) {
                setShowPoints(true)
            } else {
                setShowPoints(false)
            }
        }, 1000)

        return () => {
            clearInterval(timer)
        }
    }, [customTimestamp])

    const clickHandle = () => {
        if (navToLive && hostId.current !== 0) {
            joinChat(hostId.current, navigate);
        }
    }
    return (
        <div className={s.banner} onClick={clickHandle} style={{backgroundImage: `url(${isTiviaBanner ? triviaBgUrl : bgUrl})`}}>
            {/* dangerouslySetInnerHTML={{__html:title}} */}
            {/* {children} */}
            <div className="title fs23 fb color-white">
                {title}

            </div>
            {showAmount && <div className="accout fs80 fb color-white">
                {amount} {unit}
            </div>}
            {showTimer && <div className="title fs16 fb color-white">
                {state === 1 && t('Starting in xx', {time: timeStr ? timeStr : '--:--:--'})}
                {state === 2 && t('Live Now')}
                {state === 0 && t('Thanks for parcitipating')}
                {state === 4 && (timeStr ? t('Starting in xx', {time: timeStr}) : t('Ended'))}
            </div>}
            {showTimerShare && <div className={`title mt10 fs14 color-white ${s.showTimeerShare}`}>
                {/* {t('End in {{time}}', {time: timeStr ? timeStr : '--:--:--'})} */}
                {t('Event Paused. Stay Tuned')}
            </div>}
            {
                isHomeBanner && <div className={s.logo}>
                    <img src={'./UI/Banner/UI_Banner_EFAS-Z_01.webp'} height={30} />
                </div>
            }
            {
                isHomeBanner && showPoints && <div className={s.points}>
                    <img src={points} height={50}/>
                </div>
            }
        </div>
    )
}
const mapStateToProps = ({app}) => {
    return {
        userInfo: app.userInfo,
        showBalance: app.showBalance
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setShowCharContent: (params) => {
            return dispatch({
                type: "app/setShowCharContent",
                payload: params,
            });
        },
        setCurentOtherUserInfo: (params) => {
            return dispatch({
                type: "app/setCurentOtherUserInfo",
                payload: params,
            });
        },
        setShowBalance: (params) => {
            return dispatch({
                type: "app/setShowBalance",
                payload: params,
            });
        },
    }
}
export default memo(connect(mapStateToProps, mapDispatchToProps)(Navs));
