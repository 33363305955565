import React,{lazy,Suspense} from "react";
import {Routes, Route, Navigate} from "react-router-dom";
import Login from "@/views/public/Login";
import LoginNew from "@/views/public/LoginNew";

import {
    _public,
    _main,
    _game,
    mainHome,
    mainExplore,
    mainMyclubs,
    mainProfile,
    gameTrillionaireMission,
    _battle,
    gameTrillionaireBattle,
    gameTrillionaireBattleLeaderBoard,
    gameTrillionaireBattling,
    gameTrillionaireEcosystem,
    gameTrillionaireEcosystemDetail,
    gameTrillionaireAssets,
    gameShareDetail,
    gameReferral,
    gameMomentReferral,
    publicChats,
    publicWishCreate,
    publicWishLink,
    publicWishClaim,
    publicWishes,
    publicNftContent,
    _mainNft,
    mainNftNftPanel,
    mainNftBidMyBids,
    mainNftBidHistory,
    mainNftBidMynft,
    mainNftBidSweep,
    mainNftBidDetail,
    _mainClub,
    mainClubAllClubs,
    mainClubSweepClubs,
    link,
    invite,
    callback,
    balance,
    gameTrillionaire,
    gameStake,
    gameTrillionaireMissionDetailPath,
    gameTrillionaireMissionDetail,
    gameMemeClubUserDetail,
    gameShareUpPoint,
    gameMemeClub,
    gameMemeClubCreat,
    gameMemeClubDetail,
    gameMemeClubNftDetail,
    gameMomentClub,
    gameMomentClubExplore,
    gameMomentClubLauncher,
    gameMomentReferralEarn,
    gameMomentClubTge,
    gameMomentClubAssets,
    gameMemeClubAssets,
    gameMemeClubRevealMemePack,
    gameMemeClubTge,
    gameMemeClubLauncher,
    gameMemeClubLottery,
    gameMomentClubCreat,
    gameMomentClubPoints,
    gameMomentClubTask,
    gameMemeClubChat,
    gameMomentClubDetail,
    gameMomentTiktokSelect,
    gameMomentClubRevealMomentPack,
    publicQuickLinkMoments,
    gameMomentClubUserDetail
} from "./config"
// const Login = lazy(() => import("@/views/public/Login"));
const NewHome = lazy(() => import('@/views/NewHome'));
const NewExplore = lazy(() => import('@/views/NewExplore'));
const NFTPanel = lazy(() => import('@/views/main/Bid/NFTPanel'));
const AllClubs = lazy(() => import('@/views/main/Club/AllClubs'));
const SweepClubs = lazy(() => import('@/views/main/Club/SweepClubs'));
const Profile = lazy(() => import('@/views/main/Profile'));
const Chat = lazy(() => import('@/views/public/Chat'));
const Callback = lazy(() => import('@/views/public/Callback'));
const Balance = lazy(() => import('src/common/Balance'));
const Link = lazy(() => import('src/common/Link'));
const Referral = lazy(() => import('@/views/games/Referral'));
const MomentReferral = lazy(() => import('@/views/games/MomentReferral'));
const WishCreate = lazy(() => import('src/common/WishCreate'));
const WishContent = lazy(() => import('@/views/public/WishContent'));
const NftContent = lazy(() => import('@/views/public/NftContent'));
const WishLink = lazy(() => import('src/common/WishLink'));
const WishClaim = lazy(() => import('src/common/WishClaim'));
// import Rank from "@/views/games/trillionaire/Rank";
const Invite = lazy(() => import('src/common/Invite'));
const BidMyBids = lazy(() => import('src/views/main/Bid/BidMyBids'));
const BidItemDetail = lazy(() => import('src/views/main/Bid/BidItemDetail'));
const BidHistory = lazy(() => import('src/views/main/Bid/BidHistory'));
const ModelMyNFT = lazy(() => import('src/views/main/Bid/ModelMyNFT'));
const BidSweepNFT = lazy(() => import('src/views/main/Bid/BidSweepNFT'));
const ShareDetail = lazy(() => import('@/views/games/ShareDetail'));
const EcosystemDetail = lazy(() => import('src/views/games/trillionaire/Ecosystem/WarfareDetail'));
const Ecosystem = lazy(() => import('@/views/games/trillionaire/Ecosystem/comingsoon'));
const MyClubs = lazy(() => import('@/views/main/MyClubs'));
const BattleHome = lazy(() => import('@/views/games/trillionaire/BattleHome'));
const BattleLeaderBoard = lazy(() => import('@/views/games/trillionaire/Battle/BattleLeaderBoard'));
const Battling = lazy(() => import('@/views/games/trillionaire/Battle/Battling'));
const Mission = lazy(() => import('@/views/games/trillionaire/Mission/'));
const Assets = lazy(() => import('@/views/games/trillionaire/Assets'));
const Stake = lazy(() => import('@/views/games/Stake'));
const Launcher = lazy(() => import('@/views/games/MemeClub/Launcher'));
const Dispatch = lazy(() => import('@/views/games/trillionaire/Mission/Dispatch'));
const ShareUpPoints = lazy(() => import('@/views/games/ShareUpPoints'));
const MemeClub = lazy(() => import('@/views/games/MemeClub/ClubList'));
const MemeClubDetail = lazy(() => import('@/views/games/MemeClub/ClubDetail'));
const MemeClubCreat = lazy(() => import('@/views/games/MemeClub/Creat'));
const RevealMemePack = lazy(() => import('@/views/games/MemeClub/RevealMemePack'));
const MemeClubUserDetail = lazy(() => import('@/views/games/MemeClub/MemeClubUserDetail'));
const MemeClubTge = lazy(() => import('@/views/games/MemeClub/TGE'));
const MemeLottery = lazy(() => import('@/views/games/MemeClub/MemeLottery'));
const MemeCreate = lazy(() => import('@/views/games/MomentClub/MemeCreate'));
const MemeClubAssets = lazy(() => import('@/views/games/MemeClub/Assets'));
const MemeExplore = lazy(() => import('@/views/games/MomentClub/MemeExplore'));
const MemeLauncher = lazy(() => import('@/views/games/MomentClub/MemeLauncher'));
const MomentLauncher = lazy(() => import('@/views/games/MomentClub/MomentLauncher'));
const MomentPoints = lazy(() => import('@/views/games/MomentClub/MomentPoints'));
const MomentTask = lazy(() => import('@/views/games/MomentClub/MomentTask')); 
const MomentReferralEarn = lazy(() => import('@/views/games/MomentReferralEarn'));
const MemeTge = lazy(() => import('@/views/games/MomentClub/MemeTge'));
const MemeAssets = lazy(() => import('@/views/games/MomentClub/MemeAssets'));
const MemeClubChat = lazy(() => import('@/views/games/MemeClub/Chat'));
const MomentClubCreat = lazy(() => import('@/views/games/MomentClub/Creat'));
const MomentClubDetail = lazy(() => import('@/views/games/MomentClub/ClubDetail'));
const MomentTiktokSelect = lazy(() => import('@/views/games/MomentClub/TiktokVideoSelect'));
const ClubDetailTotal = lazy(() => import('@/views/games/MomentClub/ClubDetailTotal'));
const RevealMomentPack = lazy(() => import('@/views/games/MomentClub/RevealMomentPack'));
const QuickLinkMoments = lazy(() => import('@/views/public/QuickLinkMoments'));
const MomentUserDetail = lazy(() => import('@/views/games/MomentClub/MomentUserDetail'));
const routes = () => {
    return (
        // <Suspense fallback={<div>Loading...</div>}>
        <Routes>
            {/* <Route path="/" index element={<Home />} /> */}
            <Route path="/" index element={<LoginNew/>}/>
            <Route path={callback} element={<Callback/>}/>
            <Route path={`${publicQuickLinkMoments}/:clubId/:clubName`} element={<QuickLinkMoments/>}/>
            {/* <Route path="home" element={<Home/>}/> */}
            {/* <Route path="explore" element={<Explore/>}></Route> */}
            {/* <Route path="rank" element={<Rank/>}></Route> 改为 Battle 地址为 gameTrillionaireBattlePath */}
            <Route path={balance} element={<Balance/>}></Route>
            <Route path={`${link}/:type`} element={<Link/>}></Route>
            <Route path={invite} element={<Invite/>}/>
            {/* <Route path="chats/:userId" element={<ChatContent/>}/> */}
            <Route path={_main}>
                <Route index element={<NewHome/>}/>
                <Route path={mainHome} element={<NewHome/>}/>
                <Route path={mainExplore} element={<NewExplore/>}/>
                <Route path={mainMyclubs} element={<MyClubs/>}/>
                <Route path={mainProfile} element={<Profile/>}/>
                <Route path={_mainNft}>
                    <Route index element={<NFTPanel/>}/>
                    <Route path={mainNftNftPanel} element={<NFTPanel/>}/>
                    <Route path={mainNftBidMyBids} element={<BidMyBids/>}/>
                    <Route path={mainNftBidHistory} element={<BidHistory/>}/>
                    <Route path={mainNftBidMynft} element={<ModelMyNFT/>}/>
                    <Route path={mainNftBidSweep} element={<BidSweepNFT/>}/>
                    <Route path={`${mainNftBidDetail}/:tokenAddr/:tokenId`} element={<BidItemDetail/>}/>
                </Route>
                <Route path={_mainClub}>
                    <Route index element={<AllClubs/>}/>
                    <Route path={mainClubAllClubs} element={<AllClubs/>}/>
                    <Route path={`${mainClubSweepClubs}/:cardType`} element={<SweepClubs/>}/>
                </Route>
            </Route>

            {/* <Route path="events" element={<Events/>}>
                <Route index element={<Navigate to="how-points-work" replace={true}/>}/>
                <Route path="how-points-work" element={<HowPointsWork/>}/>
                <Route path="my-quests" element={<MyQuest/>}/>
                <Route path="points-calculator" element={<Calculator/>}/>
                <Route path="statistic" element={<Statistic/>}/>
            </Route> */}
            {/* <Route path="leaderboard" element={<Leaderboard/>}/> */}
            <Route path={_game}>
                <Route path={gameTrillionaire}>
                    <Route index element={<Mission/>}/>
                    <Route path={gameTrillionaireMission}>
                        <Route index element={<Mission/>}/>
                        <Route path={`${gameTrillionaireMissionDetail}/:workId`} element={<Dispatch/>}/>
                    </Route>
                    <Route path={gameTrillionaireBattle} element={<BattleHome/>}/>
                    <Route path={_battle}>
                        <Route path={`${gameTrillionaireBattleLeaderBoard}`} element={<BattleLeaderBoard/>}/>
                        <Route path={`${gameTrillionaireBattling}`} element={<Battling/>}/>
                    </Route>
                    <Route path={gameTrillionaireEcosystem}>
                        <Route index element={<Ecosystem/>}/>
                        <Route path={`${gameTrillionaireEcosystemDetail}/:areaId`} element={<EcosystemDetail/>}/>
                    </Route>
                    <Route path={gameTrillionaireAssets} element={<Assets/>}/>
                </Route>
                <Route path={gameShareDetail} element={<ShareDetail/>}/>
                <Route path={gameReferral} element={<Referral/>}/>
                <Route path={gameStake} element={<Stake/>}/>
                {/* <Route path={gameShareUpPoint} element={<ShareUpPoints/>}/> */}
                <Route path={gameMemeClub} >
                    <Route index element={<MemeClub/>}/>
                    <Route path={gameMemeClubCreat} element={<MemeClubCreat/>}/>
                    <Route path={gameMemeClubTge} element={<MemeClubTge/>}/>
                    <Route path={gameMemeClubLauncher} element={<Launcher/>}/>
                    <Route path={`${gameMemeClubRevealMemePack}/:clubUserId`} element={<RevealMemePack/>}/>
                    <Route path={gameMemeClubAssets} element={<MemeClubAssets/>}/>
                    <Route path={`${gameMemeClubUserDetail}/:userId`} element={<MemeClubUserDetail/>}/>
                    <Route path={`${gameMemeClubDetail}/:clubId`} element={<MemeClubDetail/>}/>
                    {/* <Route path={`${gameMemeClubChat}/:clubId`} element={<MemeClubChat/>}/> */}
                    <Route path={gameMemeClubLottery} element={<MemeLottery/>}/>
                </Route>
                <Route path={gameMomentClub} >
                    <Route index element={<MemeExplore/>}/>
                    <Route path={gameMomentClubExplore} element={<MemeExplore/>}/>
                    <Route path={gameMomentReferral} element={<MomentReferral/>}/>
                    <Route path={gameMomentReferralEarn} element={<MomentReferralEarn/>}/>
                    <Route path={gameMomentClubLauncher} element={<MomentLauncher/>}/>
                    <Route path={gameMomentClubTge} element={<MemeTge/>}/>
                    <Route path={gameMomentClubAssets} element={<MemeAssets/>}/>
                    <Route path={`${gameMomentClubCreat}/:videoType`} element={<MemeCreate/>}/>
                    <Route path={gameMomentClubPoints} element={<MomentPoints/>}/>
                    <Route path={gameMomentClubTask} element={<MomentTask/>}/>
                    <Route path={gameMomentTiktokSelect} element={<MomentTiktokSelect/>}/>
                    {/*<Route path={`${gameMomentClubDetail}/:clubId`} element={<MomentClubDetail/>}/>*/}
                    <Route path={`${gameMomentClubDetail}`} element={<ClubDetailTotal/>}/>
                    <Route path={`${gameMomentClubRevealMomentPack}/:clubUserId`} element={<RevealMomentPack/>}/>
                    <Route path={`${gameMomentClubUserDetail}/:clubUserId`} element={<MomentUserDetail/>}/>
                    
                </Route>
            </Route>

            <Route path={_public}>
                <Route path={`${publicChats}/:userId`} element={<Chat/>}/>
                <Route path={publicWishCreate} element={<WishCreate/>}/>
                <Route path={publicWishLink} element={<WishLink/>}/>
                <Route path={publicWishClaim} element={<WishClaim/>}/>
                <Route path={`${publicWishes}/:userId`} element={<WishContent/>}/>
                <Route path={`${publicNftContent}/:tokenAddr/:tokenId`} element={<NftContent/>}/>
            </Route>


            {/* <Route path="warfare" element={<Bid/>}>
                <Route index element={<WarfareHome/>}/>
                <Route path="detail/:areaId" element={<WarfareDetail/>}/>
                <Route path="map" element={<WarfareMap/>}/>
            </Route> */}


            <Route path="*" element={<Navigate to='/' replace={true}/>}/>
        </Routes>
        // </Suspense>
    );
};

export default routes;
