import { BigNumber, ethers } from "ethers";
import { abi_momentClub, abi_token } from "@/utils/abi";
import { getConfig } from "./common";
import { getGasConfig } from "@/utils/gas";
import momentChainToken from "@/utils/json/momentChainToken.json";
import {
    getLfgAllowance,
} from "@/utils/bidNftWeb3";
import {
    LfgMainnetId,
    LfgTestnetId,
} from "@/utils/env";
import {
    getWeb3Config,
} from "@/utils/common";
import {
    lfgApprove,
} from "@/utils/lfgStake";
import { erc721Abi } from "viem";
import { getGeneralPaymasterInput } from "viem/zksync";
function stringToHex(str) {
    const val = [...str].map(c => c.charCodeAt(0).toString(16).padStart(2, 0)).join``
    return '0x' + val;
}


function limitDecimals(value, decimals) {
    const parts = value.split('.');
    if (parts.length === 2 && parts[1].length > decimals) {
        parts[1] = parts[1].slice(0, decimals);
        return parts.join('.');
    }
    return value;
}

async function getmomentClubContract(wallets, coinId, isWriter) {

    const { chainId } = momentChainToken.find(i => i?.ID === coinId)
    const web3Config = await getWeb3Config();
    const contractInfo = web3Config?.contractList?.find((item) => (item.coinId === coinId));
    let momentFactoryContract = contractInfo?.momentFactoryContract;
    console.log("[momentClub contract Addr]", momentFactoryContract, "[chainId]", chainId);
    //console.log(embeddedWallet);

    const embeddedWallet = wallets.find((wallet) => (wallet.walletClientType === 'privy'));
    await embeddedWallet.switchChain(chainId);
    const privyProvider = await embeddedWallet.getEthereumProvider();
    const provider = new ethers.providers.Web3Provider(privyProvider);

    isWriter = false; //Since USE AGW, we don't need to sign the transaction by privy
    if (isWriter) {
        const signer = provider.getSigner();

        const gasConfig = await getGasConfig(signer);
        gasConfig.gasLimit = chainId === 11124 ? 100000000 : 25000000;

        const contract = new ethers.Contract(momentFactoryContract, abi_momentClub, provider).connect(signer);
        return { contract, addr: embeddedWallet.address, gasConfig, embeddedWallet, coinContract: contractInfo?.coinContract, contractInfo };
    } else {
        const contract = new ethers.Contract(momentFactoryContract, abi_momentClub, provider);
        return { contract, addr: embeddedWallet.address, embeddedWallet, coinContract: contractInfo?.coinContract, contractInfo };
    }
}

const mintMomentToken = ({ wallets, clubId, cardArr,tgeType, amountArr,amount, coinId}) => {
    return new Promise(async (resolve, reject) => {
        try {
            const { contract, addr, gasConfig } = await getmomentClubContract(wallets, coinId, true);
            console.log("mintMomentTokenParam", {
                contract: contract,
                addr: addr,
                clubId: clubId,
                cardArr: cardArr,
                amountArr: amountArr,
                gasConfig: gasConfig});

            /*let newAmountArr = [];
            if (amountArr?.length > 0){
                for (let i = 0; i < amountArr?.length; i++){
                    newAmountArr.push(ethers.utils.parseEther(amountArr[i].toString()));
                }
            }
            console.log("newAmountArr", newAmountArr, amountArr);*/
            if(tgeType === 1){
                contract.mintDrawMoment(ethers.BigNumber.from(clubId), cardArr, amountArr, gasConfig).then(res => {
                    console.log("[mintMomentToken] ", res);
                    resolve(res)
                }).catch(e => {
                    console.log("[mintMomentToken exception]", e);
                    if (e.message.indexOf('(reading \'switchChain\')') > 0) {
                        console.log("privy need login")
                        reject('need login');
                    } else {
                        reject('Transaction Failed');
                    }
                });
            }else{
                contract.mintFairMoment(ethers.BigNumber.from(clubId), amount, gasConfig).then(res => {
                    console.log("[mintMomentToken] ", res);
                    resolve(res)
                }).catch(e => {
                    console.log("[mintMomentToken exception]", e);
                    if (e.message.indexOf('(reading \'switchChain\')') > 0) {
                        console.log("privy need login")
                        reject('need login');
                    } else {
                        reject('Transaction Failed');
                    }
                });
            }
           
        } catch (error) {
            console.log("[mintMomentToken exception]", error);
            if (error.message.indexOf('(reading \'switchChain\')') > 0) {
                console.log("privy need login")
                reject('need login');
            } else {
                reject('Transaction Failed');
            }
        }
    })
}

const checkTransaction = (wallets, transactionHash, chainId) => {
    return new Promise(async (resolve, reject) => {
        try {
            const embeddedWallet = wallets.find((wallet) => (wallet.walletClientType === 'privy'));
            await embeddedWallet.switchChain(chainId);

                const privyProvider = await embeddedWallet.getEthereumProvider();
    const provider = new ethers.providers.Web3Provider(privyProvider);

            const transaction = await provider.getTransaction(transactionHash);

            console.log("checkNftInfo transaction", transaction);

            resolve(transaction);
        } catch (error) {
            console.log("[checkTransaction exception]", error);
            if (error.message.indexOf('(reading \'switchChain\')') > 0) {
                console.log("privy need login")
                reject('need login');
            } else {
                reject('Transaction Failed');
            }
        }
    })
}

const getAssetTransfers = (wallets, chainId, transaction) => {
    return new Promise(async (resolve, reject) => {
        try {
            const embeddedWallet = wallets.find((wallet) => (wallet.walletClientType === 'privy'));
            await embeddedWallet.switchChain(chainId);

                const privyProvider = await embeddedWallet.getEthereumProvider();
    const provider = new ethers.providers.Web3Provider(privyProvider);

            const blockNumberString = '0x' + transaction.blockNumber.toString(16).toUpperCase();
            const result = await provider.send('alchemy_getAssetTransfers', [
                {
                    category: ["erc20", "erc721"],
                    toAddress: transaction.from.toString(),
                    fromBlock: blockNumberString,
                    toBlock: blockNumberString
                }
            ]);
            resolve(result);
        } catch (error) {
            console.log("[getAssetTransfers exception]", error);
            if (error.message.indexOf('(reading \'switchChain\')') > 0) {
                console.log("privy need login")
                reject('need login');
            } else {
                reject('Transaction Failed');
            }
        }
    })
}

const getNftMetadata = (wallets, contractAddr, tokenId, chainId) => {
    return new Promise(async (resolve, reject) => {
        try {
            const embeddedWallet = wallets.find((wallet) => (wallet.walletClientType === 'privy'));
            await embeddedWallet.switchChain(chainId);

                const privyProvider = await embeddedWallet.getEthereumProvider();
    const provider = new ethers.providers.Web3Provider(privyProvider);

            const contract = new ethers.Contract(contractAddr, erc721Abi, provider);

            contract.tokenURI(tokenId).then(res => {
                console.log("[getNftMetadata] ", res);
                resolve(res);
            }).catch(e => {
                console.log("[getNftMetadata exception]", e);
                if (e.message.indexOf('(reading \'switchChain\')') > 0) {
                    console.log("privy need login")
                    reject('need login');
                } else {
                    reject('Transaction Failed');
                }
            });
        } catch (error) {
            console.log("[getNftMetadata exception]", error);
            if (error.message.indexOf('(reading \'switchChain\')') > 0) {
                console.log("privy need login")
                reject('need login');
            } else {
                reject('Transaction Failed');
            }
        }
    })
}

const sendUnsignTransactionByAgw = (unsignedTx, sendTransaction, wallets, agwAddress) => {
    return new Promise(async (resolve, reject) => {

        console.log("[sender AGW]", agwAddress);

        const embeddedWallet = wallets.find((wallet) => (wallet.walletClientType === 'privy'));

        const privyProvider = await embeddedWallet.getEthereumProvider();
        const provider = new ethers.providers.Web3Provider(privyProvider);

        console.log("[sendUnsignTransactionByAgw]", unsignedTx);
        if (unsignedTx.from) unsignedTx.from = null;

        //探测AGW是否已经部署
        provider.getCode(agwAddress).then(res => {

            if (res === '0x') {
                console.log("[AGW not deployed] for", agwAddress);
                //使用项目方GAS费托管来部署AGW
                unsignedTx.paymaster = "0x5bCa693Ca2ADC0cCe5886b92f8300C904F4E4c36";
                unsignedTx.paymasterInput = getGeneralPaymasterInput({
                    innerInput: "0x",
                });
            }

            sendTransaction(unsignedTx).then(
                resp => resolve(resp)
            ).catch(
                e => reject(e)
            );

        }).catch(e1 => {
            console.warn("[code expection]", e1.toString());

            sendTransaction(unsignedTx).then(
                resp => resolve(resp)
            ).catch(
                e => reject(e)
            );
        });
        /*
        const crossAppAccount = user.linkedAccounts.find((account) => account.type === 'cross_app');
        console.log("[crossAppAccount]", crossAppAccount);
        const address = crossAppAccount.embeddedWallets[0].address;
        console.log("[cross_app embeddedWallets]", address);
*/

    }
    )
}

const creatMomentClub = ({ wallets, coinId, initBuyAmount_, callId, value, momentConf_, signature, validUntil, creationFee_, sendTransaction, agwAddress }) => {
    // console.log(wallets);

    return new Promise(async (resolve, reject) => {
        try {
            const { contract, addr, gasConfig, contractInfo } = await getmomentClubContract(wallets, coinId, true);
            console.log(callId, initBuyAmount_, momentConf_, stringToHex(atob(signature)), gasConfig, `value:`, value, validUntil, creationFee_);
            const embeddedWallet = wallets.find((wallet) => (wallet.walletClientType === 'privy'));
            if (!contractInfo){
                reject("get config error");
                return;
            }
            const coinInfo = momentChainToken.find(i => i?.ID === coinId)
            const contractAddr = contractInfo?.momentFactoryContract;
            if (coinInfo?.isNative === 0 && (coinInfo?.ID === LfgMainnetId || coinInfo?.ID === LfgTestnetId)) {
                let currentAllowance = await getLfgAllowance(embeddedWallet, contractAddr, contractInfo?.coinContract);
                console.log('[getLfgAllowance]', { currentAllowance: ethers.utils.formatEther(currentAllowance), value });
                if (ethers.utils.formatEther(currentAllowance) < value) {
                    let amount = ethers.utils.parseEther(Number.MAX_SAFE_INTEGER.toString());
                    await lfgApprove(wallets, amount, contractInfo?.coinContract, coinInfo?.chainId, contractAddr); //approve lfg for auction As MANY as Possible
                }
            }

            const cfg = {
                //...gasConfig,
                value:ethers.utils.parseEther(limitDecimals(value.toString(), 18))
            };
           
            console.log(cfg);
            console.log("newMomentClub", callId, initBuyAmount_, creationFee_, momentConf_, validUntil);

            contract.populateTransaction.newMomentClub(callId, initBuyAmount_, creationFee_, momentConf_, validUntil, stringToHex(atob(signature)), cfg).then(unsignedTx => {

                sendUnsignTransactionByAgw(unsignedTx, sendTransaction, wallets, agwAddress).then(
                    resp => resolve(resp)
                ).catch(
                    e => reject(e)
                );
                
            }).catch(e => {
                console.log("[creatMomentClub exception]", e);
                if (e.message.indexOf('(reading \'switchChain\')') > 0) {
                    console.log("privy need login")
                    reject('need login');
                } else {
                    reject('Transaction Failed');
                }
            });

        } catch (error) {
            console.log("[creatMomentClub exception]", error);
            if (error.message.indexOf('(reading \'switchChain\')') > 0) {
                console.log("privy need login")
                reject('need login');
            } else {
                reject('Transaction Failed');
            }
        }
    })

}

const getMomentEntropyFee = (wallets, coinId) => {
    return new Promise(async (resolve, reject) => {
        try {
            
            const { contract, addr, gasConfig } = await getmomentClubContract(wallets, coinId);
            // const contract = new ethers.Contract(contractAddr, erc721Abi, provider);

            contract.getEntropyFee().then(res => {
                console.log("[getEntropyFee] ", res);
                resolve(res);
            }).catch(e => {
                console.log("[getEntropyFee exception]", e);
                if (e.message.indexOf('(reading \'switchChain\')') > 0) {
                    console.log("privy need login")
                    reject('need login');
                } else {
                    reject('Transaction Failed');
                }
            });
        } catch (error) {
            console.log("[getEntropyFee exception]", error);
            if (error.message.indexOf('(reading \'switchChain\')') > 0) {
                console.log("privy need login")
                reject('need login');
            } else {
                reject('Transaction Failed');
            }
        }
    })
}


export {
    mintMomentToken,
    creatMomentClub,
    checkTransaction,
    getAssetTransfers,
    getNftMetadata,
    getMomentEntropyFee,
    sendUnsignTransactionByAgw,
}
