import React, {ReactNode} from "react";
import {DrawerProps, Drawer} from "antd";
import {AutoStrangeRule} from "@/utils/strangeRule";

interface Props extends DrawerProps {
    children?: ReactNode;
    title?: ReactNode;
    id?: number;
}


function AntDrawer({children, id, title, ...rest}: Props) {
    return (
        <Drawer title={AutoStrangeRule(title, false)} {...rest}>{children}</Drawer>
    );
}

const MemoModal = React.memo(AntDrawer);

export default MemoModal;
