// import WebIM from "easemob-websdk/circleSdk/Easemob-chat";
import WebIM from "easemob-websdk"
import {webIMappKey} from "./configUri"


const appKey = webIMappKey;
WebIM.conn = new WebIM.connection({
  appKey: appKey,
});
WebIM.logger.disableAll();
// WebIM.logger.setLevel(0);
WebIM.logger.setConfig({
        useCache: false, // 是否缓存
        maxCache: 3 * 1024 * 1024, // 最大缓存字节
});
// 缓存全部等级日志 

export default WebIM; 
