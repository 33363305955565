import React, {memo, useEffect, useMemo, useState} from "react";
import s from "./index.module.less";
import AddToHome from "@/assets/video/AddToHome.mp4";
import WrongBrowser from "@/assets/video/WrongBrowser.mp4";
import Homepage_Web from "@/assets/video/Homepage_Web.mp4";
import {borwserOS, borwserPlatform} from "@/utils/common";
import CustomIcon from "../CustomIcon";
import {useTranslation, Trans} from 'react-i18next';

const HomeTips = ({tipsStr, iOSNotSafari = false, bg, setSkip,close}) => {
    const {t, i18n} = useTranslation();
    const installApp = async () => {
        /*
        const promptEvent = window.deferredPrompt;
        if (!promptEvent) {
            return;
        }
        promptEvent.prompt();

        const result = await promptEvent.userChoice;
        window.deferredPrompt = null;*/
        
        window.open('/?pswutlzoq=install', '_self');
    }

    const isIos = useMemo(() => {
        const os = borwserOS();
        if (os === 'iOS') {
            return true;
        } else {
            return false;
        }
    }, [])

    const isSafari = useMemo(() => {
        const platform = borwserPlatform()
        if (platform === 'safari') {
            return true;
        } else {
            return false;
        }
    }, [])

    const isAndroid = useMemo(() => {
        const os = borwserOS();
        if (os === 'android') {
            return true;
        } else {
            return false;
        }
    }, [])

    const isDesktop = !iOSNotSafari && !isAndroid && !isIos;

    const [showSkip, setShowSkip] = useState(false)

    useEffect(() => {

        if (!iOSNotSafari && isAndroid) {
            // const timer = setTimeout(()=>{
            setShowSkip(true)
            // },10000)
        }
    }, [iOSNotSafari, isAndroid])

    return (
        <div className={s.modalWrap} onClick={installApp}>
            {/* <video className={s.videBg} autoPlay={true} muted loop playsInline>
          {isDesktop &&
              <source src={Homepage_Web} type="video/mp4" />
          }
          {!isDesktop &&
              <source src={iOSNotSafari?WrongBrowser:AddToHome} type="video/mp4" />
          }
      </video> */}
            

            <div className={s.modal} style={{"maxWidth": "600px"}}>
                {!iOSNotSafari && !isAndroid && !isIos &&
                    <>
                        {/* <div className="tlc"><img src={Logo} width={130}></img></div> */}
                        <div className="fs14 tll color-white">
                            {t('To install the app, you need to follow 2 simple steps:')}<br/>
                            {t('Step 1:')}<br/>
                            <div className={s.pl20}>
                                <CustomIcon className="mr5" width={23} height={23} imgName={'Picture/UI_Picture_Safari'}
                                            />
                                {t('Tap the “More” button and choose “Install App” in the options.')}<br/>

                            </div>
                            <div className={s.pl20}>
                                <CustomIcon className="mr5" width={23} height={23} imgName={'Picture/UI_Picture_chrome'}
                                            />
                                {t('Tap the Share icon and choose “Add to Home Screen” in the options.')}<br/>
                            </div>

                            {t('Step 2:')}<br/>
                            <div className={s.pl20}>{t('Open the TURNUP app on your Home Screen')}</div>
                        </div>
                    </>
                }
                {!iOSNotSafari && isAndroid &&
                    <>
                        <div className="fs14 tll color-white">
                            {t('To install the app, you need to follow 2 simple steps:')}<br/>
                            {t('Step 1:')}<br/>
                            <div className={s.pl20}><CustomIcon width={23} height={23} className="mr5"
                                                                imgName={'Picture/UI_Picture_chrome'} /><span
                                className="fb"></span>{t('Tap the “More” button and choose “Install App” in the options.')}
                            </div>
                            {t('Step 2:')}<br/>
                            <div className={s.pl20}>{t('Open the TURNUP app on your Home Screen')}</div>
                        </div>
                    </>
                }
                {!iOSNotSafari && isSafari && isIos &&
                    <>
                        <div className="fs14 tll color-white">
                            {t('To install the app, you need to follow 2 simple steps:')}<br/>
                            {t('Step 1:')}<br/>
                            <div className={s.pl20}><CustomIcon width={23} height={23} className="mr5"
                                                                imgName={'Picture/UI_Picture_Safari'}/><span
                                className="fb"></span>{t('Tap the Share icon and choose “Add to Home Screen” in the options.')}
                            </div>
                            {t('Step 2:')}<br/>
                            <div className={s.pl20}>{t('Open the TURNUP app on your Home Screen')}</div>
                        </div>
                    </>
                }
                {iOSNotSafari &&
                    <>
                        <div className="fs14 color-white">
                            <Trans
                                i18nKey="To install TURNUP, you need to add this website to your homescreen Please open turnup.so in Safari to install the app."
                                defaults="To install <caClass>TURNUP</caClass>, you need to add this website to your homescreen.<br>Please open turnup.so in <caClass>Safari</caClass> to install the app." // optional defaultValue
                                components={{caClass: <span className="c-a"></span>, br: <br/>}}
                            />
                        </div>
                    </>
                }
            </div>
            {
                showSkip && <div className={s.skip} onClick={() => {
                    setSkip(true)
                    close()
                } }>
                    {t('Having trouble? Tap here.')}
                </div>
            }

        </div>
    )
}
export default memo(HomeTips);
