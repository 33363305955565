export default class Db {
    key(k) {
      return k.replace(/^_TT_/, "");
    }
  
    isKey(k) {
      return (/^_TT_.+/).test(k);
    }
  
    constructor(setStore) {
      
      this.setStore = setStore;
      this.store = {};
      let store = {};
      const keys = Object.keys(localStorage);
      
      for (let key of keys) {
        if (this.isKey(key)) {
          console.log("(key)",key);
          store[this.key(key)] = JSON.parse(localStorage.getItem(key) !== 'undefined' ? localStorage.getItem(key) : '{}');
        }
      }
      this.update(store);
      this.set();
    }
  
    get(key) {
      return this.store[key];
    }
  
    set() {
      this.setStore(this.store);
    }
  
    update(obj) {
      for (let key in obj) {
        this.updateOne(key, obj[key]);
      }
    }
  
    updateOne(key, val) {
      if(!key) return
      this.store[key] = val;
      localStorage.setItem("_TT_" + key, JSON.stringify(val));
    }
  
    updateItem(key, props) {
      let item = JSON.parse(JSON.stringify(this.store[key] || {}));
      if (!item) {
        item = {};
      }
      for (let k in props) {
        if (props[k] === null) {
          delete item[k];
        } else {
          item[k] = props[k];
        }
      }
      this.updateOne(key, item);
    }
  }