import React, {ReactNode} from "react";
import {Button, ButtonProps} from "antd";
import {AutoStrangeRule} from "@/utils/strangeRule";

interface Props extends ButtonProps {
    children?: ReactNode;
    id?: number;
}


function AntButton({children, id, ...rest}: Props) {
    const child = React.Children.map(children, child => {
        if (typeof child === 'string') {
            return <span>{child}</span>;
        }

        return child;
    });

    return (
        <Button {...rest}>{AutoStrangeRule(child)}</Button>
    );
}

const MemoButton = React.memo(AntButton);

export default MemoButton;
