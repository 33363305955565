import React, {memo, useMemo, useState, useEffect} from "react";
import {connect} from "react-redux";
import s from "./index.module.less";
import CustomIcon from "../CustomIcon";

import {useNavigate, useLocation} from "react-router-dom";
import {joinChat} from "@/utils/common";
import TaskConf from "@/utils/json/TaskConf.json";
import {useTranslation, Trans} from 'react-i18next';
import {displayNewbieTaskStartId} from "@/utils/env";
import { AutoStrangeRule } from "@/utils/strangeRule";
import {
    getMemeTgeNoticeList
} from "@/utils/memeClub";
import {
    gameMemeClubAssetsPath,
    gameMemeClubPath,
    gameMemeClubTgePath,
    gameMemeClubLauncherPath,
} from "@/routes/config";

const GameMemeClubNavs = (
    {
        userInfo,
        setShowBalance,
        memeAssetUnpackList,
        setMemeAssetUnpackList,
        memeAssetUnpackListQueryLastTime,
        setMemeAssetUnpackListQueryLastTime,
    }
) => {
    const navigate = useNavigate();
    const location = useLocation();
    const {userId, selfData} = useMemo(() => {
        return userInfo || {}
    }, [userInfo]);
    const {
        buyFirstKey, payEntryFee, task
    } = useMemo(() => {
        return selfData || {}
    }, [selfData]);


    const {t, i18n} = useTranslation();
    const [queryMemeAssetUnpackListCount, setQueryMemeAssetUnpackListCount] = useState(0);

    const navArr = [
        {
            id: 3,
            icon: 'Button/UI_Button-D_EXPORE',
            label: 'DNA Launcher',
            labelName: AutoStrangeRule(t('DNA Launcher')),
            path: gameMemeClubLauncherPath
        },
        {
            id: 0,
            icon: 'Button/UI_Button-D_Ecosystem',
            label: 'Explore',
            labelName: AutoStrangeRule(t('Explore')),
            path: gameMemeClubPath
        }, {
            id: 1,
            icon: 'Button/UI_Button-D_Mission',
            label: 'TGE Meme',
            labelName: AutoStrangeRule(t('TGE Meme')),
            path: gameMemeClubTgePath
        }, {
            id: 2,
            icon: 'Button/UI_Button-D_Assets',
            label: 'My Assets',
            labelName: AutoStrangeRule(t('My Assets')),
            path: gameMemeClubAssetsPath
        }
    ]

    useEffect(() => {
        let nowTime = new Date().getTime();
        if (nowTime - memeAssetUnpackListQueryLastTime >= 60000){
            setMemeAssetUnpackListQueryLastTime(nowTime);
            getMemeTgeNoticeList().then(res => {
                if (res?.noticeList?.length > 0){
                    setMemeAssetUnpackList(res?.noticeList);
                }
                else{
                    setMemeAssetUnpackList(null);
                }
            }).catch(e => {
                console.error(e);
            })
        }
        setTimeout(() => {
            setQueryMemeAssetUnpackListCount(queryMemeAssetUnpackListCount + 1);
        }, 1000);
    }, [queryMemeAssetUnpackListCount]);

    const unreadMemeAssetNewPackNum = useMemo(() =>{
        let res = 0;
        if (memeAssetUnpackList?.length > 0){
            for (let i = 0; i < memeAssetUnpackList?.length; i++){
                if (!memeAssetUnpackList[i].isRead){
                    res++;
                }
            }
        }
        return res;
    }, [memeAssetUnpackList])

    const linkToPage = (link, label) => {
        // if ((label === "Chat") && (!(buyFirstKey && payEntryFee) || userInfo?.selfData?.wishNeedClaim)) {
        //   if (userInfo?.selfData?.wishNeedClaim) {
        //     navigate(publicWishClaimPath)
        //   } else if (!(buyFirstKey && payEntryFee)) {
        //     navigate(balancePath)
        //   } else {
        //     navigate(balancePath)
        //   }
        // } else {
        //   if (label === 'Chat') {
        //     joinChat(userId, navigate);
        //   } else {
        //     window.location.replace("#/" + link);
        //     navigate(link, { replace: true });

        //   }
        // }
        navigate(link);
    }

    return (
        <div className={s.footNavWrap}>
            <div className={s.footNav}>
                {
                    navArr.map((item, ind) => {
                        return (
                            <div className={`${s.item} ${location.pathname === item.path ? s.active : ''}`} key={ind}
                                 onClick={() => {
                                     linkToPage(item.path, item.label)
                                 }}>
                                <div className={`${s.iconWrap}`}>
                                    <CustomIcon
                                        width={32} height={32}
                                        imgName={`${location.pathname === item.path ? item.icon + '_On' : item.icon}`}
                                        />
                                </div>
                                <div className={`${s.label} fb`}>{item.labelName}</div>
                                {
                                    (item?.id === 2 && unreadMemeAssetNewPackNum > 0) &&
                                    <>
                                        <div className={`${s.memeAssetRedPoint} flex_center_center`}>
                                            <span className={`${s.memeAssetRedPointText}`}>
                                                {unreadMemeAssetNewPackNum > 99 ? '99+':unreadMemeAssetNewPackNum}
                                            </span>
                                        </div>
                                    </>
                                }
                            </div>
                        )
                    })
                }
            </div>
        </div>
    )
}
const mapStateToProps = ({app}) => {
    return {
        userInfo: app.userInfo,
        showBalance: app.showBalance,
        memeAssetUnpackList: app.memeAssetUnpackList,
        memeAssetUnpackListQueryLastTime: app.memeAssetUnpackListQueryLastTime,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setShowCharContent: (params) => {
            return dispatch({
                type: "app/setShowCharContent",
                payload: params,
            });
        },
        setCurentOtherUserInfo: (params) => {
            return dispatch({
                type: "app/setCurentOtherUserInfo",
                payload: params,
            });
        },
        setShowBalance: (params) => {
            return dispatch({
                type: "app/setShowBalance",
                payload: params,
            });
        },
        setMemeAssetUnpackList: (params) => {
            return dispatch({
                type: "app/setMemeAssetUnpackList",
                payload: params,
            });
        },
        setMemeAssetUnpackListQueryLastTime: (params) => {
            return dispatch({
                type: "app/setMemeAssetUnpackListQueryLastTime",
                payload: params,
            });
        },
    }
}
export default memo(connect(mapStateToProps, mapDispatchToProps)(GameMemeClubNavs));
